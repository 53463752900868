.blog-container {
    font-family: "Raleway";
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
}

.articles-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.article-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.article-card h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
}

.article-card p {
    font-size: 1rem;
    color: #555;
}

.article-card small {
    font-size: 0.9rem;
    color: #888;
    display: block;
    margin-top: 15px;
}

.pagination {
    text-align: center;
    margin-top: 30px;
}

.page-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
}

.page-btn:hover {
    background-color: #0056b3;
}

.page-btn.active {
    background-color: #0056b3;
    font-weight: bold;
}
  